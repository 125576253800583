import React from 'react';
import { CategoryID, MacroCategoryID } from '@sbt-web/network/types';
import { Icon, IconSize, SelectBox } from '@sbt-web/ui';
import { getMacroOfCategoryID } from '@sbt-web/utils';

type Props = {
  value?: CategoryID;
  onChange: (value: CategoryID) => void;
};

const MOTORI_CATEGORIES = getMacroOfCategoryID(
  MacroCategoryID.Motori
).categories;

const VALUE_LIST = MOTORI_CATEGORIES.map((category) => ({
  name: category.label,
  value: category.id,
}));

export const CategoryInput = ({ value, onChange }: Props) => {
  const selectedIndex = VALUE_LIST.findIndex((cat) => cat.value === value);
  const selectedIndices = selectedIndex >= 0 ? [selectedIndex] : [];

  return (
    <SelectBox
      name="Categoria"
      flavor="large"
      allowGroupSelection
      dropdownHeight="minimal"
      icon={<Icon name="Category" iconSize={IconSize.MD} />}
      valueList={VALUE_LIST}
      selectedIndices={selectedIndices}
      onSelect={([option]) => {
        if (option?.value) {
          onChange(option.value as CategoryID);
        }
      }}
    />
  );
};
