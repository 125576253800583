import React from 'react';
import SearchModal, { useSearchModal, type Tab } from '../../../SearchModal';
import { Verticals } from '../../Verticals';
import type { Vertical } from '../../categories';

const VERTICAL_TAB_MAP: Record<Vertical['key'], Tab> = {
  motori: 'Motori',
  market: 'Market',
  immobili: 'Immobili',
  lavoro: 'Lavoro',
} as const;

export const Mobile = () => {
  const { open, onClose, openTab, activeTab } = useSearchModal('Motori');

  const handleOnItemClick = (vertical: Vertical) => {
    openTab(VERTICAL_TAB_MAP[vertical.key]);
  };

  return (
    <>
      <Verticals onItemClick={handleOnItemClick} />
      <SearchModal open={open} onClose={onClose} activeTab={activeTab} />
    </>
  );
};
