import React, { useMemo, useState } from 'react';
import type { CategoryID } from '@sbt-web/network/types';
import {
  Combobox,
  type ComboboxOption,
  type ComboboxPlainOption,
} from '@sbt-web/ui';
import { comboboxOptionMapper, useValueList } from '../hooks/useValueList';

type Props = {
  label: string;
  categoryID: CategoryID;
  value: string | undefined;
  dataSource: string;
  disabled: boolean;
  onChange: (value: string | undefined) => void;
};

export const ComboboxInput = ({
  label,
  categoryID,
  value,
  dataSource,
  disabled,
  onChange,
}: Props) => {
  const [hideMostFrequent, setHideMostFrequent] = useState(false);

  const valueListData = useValueList(dataSource, categoryID, disabled);

  const { value: valueList } = valueListData;

  const plainOptions = useMemo<ComboboxPlainOption[]>(
    () => valueList?.values?.map(comboboxOptionMapper) ?? [],
    [valueList]
  );

  const groupedOptions = useMemo<ComboboxOption[]>(() => {
    if (!valueList?.topValues) return plainOptions;
    return [
      {
        label: 'Più frequenti',
        options: valueList.topValues.map(comboboxOptionMapper),
      },
      {
        label: 'Tutti',
        options: plainOptions,
      },
    ];
  }, [plainOptions, valueList]);

  const options = hideMostFrequent ? plainOptions : groupedOptions;

  const handleOnSearch = (input?: string) => {
    setHideMostFrequent(!!input);
  };

  return (
    <Combobox
      ariaLabel={label}
      onSearch={handleOnSearch}
      options={options}
      size="lg"
      disabled={disabled}
      value={value}
      onValueChange={onChange}
      placeholder="Qualsiasi"
      hideClearIfNotNeeded
    />
  );
};
