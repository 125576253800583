import { CategoryID, SearchParam } from '@sbt-web/network/types';
import type { MotoriSearchQueryParams } from './types';

export const VARIANTS = {
  COMPACT: 'compact',
  LINEAR: 'linear',
} as const;

export const CATEGORIES_WITH_BRANDS_AND_SIZES: Array<CategoryID> = [
  CategoryID.Auto,
  CategoryID.Moto,
];

export const SEARCH_PARAMS_BY_CATEGORY: Partial<
  Record<CategoryID, MotoriSearchQueryParams>
> = {
  [CategoryID.Auto]: {
    brand: SearchParam.CarBrand,
    model: SearchParam.CarModel,
  },
  [CategoryID.Moto]: {
    brand: SearchParam.BikeBrand,
    model: SearchParam.BikeModel,
  },
};
