import React from 'react';
import { CategoryID } from '@sbt-web/network/types';
import { ComboboxInput } from '../ComboboxInput';

type Props = {
  brand: string | undefined;
  disabled: boolean;
  categoryID: CategoryID;
  value: string | undefined;
  onChange: (value: string | undefined) => void;
};

const ENABLED_CATEGORIES: Array<CategoryID> = [
  CategoryID.Auto,
  CategoryID.Moto,
];

export const buildDataSource = (categoryID: CategoryID, brand?: string) => {
  if (!brand) return '';

  switch (categoryID) {
    case CategoryID.Auto:
      return `/v1/values/cars/brands/${brand}/metamodels`;
    case CategoryID.Moto:
      return `/v1/values/motorbikes/brands/${brand}/models`;
    default:
      return '';
  }
};

export const ModelInput = ({
  brand,
  disabled,
  categoryID,
  value,
  onChange,
}: Props) => {
  const disabledByBrandOrCategory =
    disabled || !brand || !ENABLED_CATEGORIES.includes(categoryID);

  const dataSource = buildDataSource(categoryID, brand);

  return (
    <ComboboxInput
      label="Modello"
      value={value}
      onChange={onChange}
      dataSource={dataSource}
      categoryID={categoryID}
      disabled={disabledByBrandOrCategory}
    />
  );
};
