import React from 'react';
import { CategoryID } from '@sbt-web/network/types';
import { GenericTab } from '../GenericTab';

export const LavoroTab = () => {
  return (
    <GenericTab
      placeholder="Cerca lavoro"
      categoryID={CategoryID.LavoroServizi}
      disableRecentSearches
    />
  );
};
