import { Variant } from '../types';
import type { Vertical } from '../../../categories';
import type { FC } from 'react';
import { VARIANTS } from '../constants';
import cn from 'classnames';

import styles from './index.module.scss';

type Props = {
  variant: Variant;
  vertical: Vertical;
};

export const SubmitButton: FC<Props> = ({ variant, vertical }) => {
  const commonClasses = [
    styles['button'],
    vertical ? styles[vertical.key] : null,
  ];

  switch (variant) {
    case VARIANTS.LINEAR:
      return (
        <button
          type="submit"
          className={cn(...commonClasses, styles['button-with-label'])}
        >
          Cerca
        </button>
      );

    case VARIANTS.COMPACT:
      return (
        <button
          type="submit"
          className={cn(...commonClasses, styles['button-with-icon'])}
        >
          <img src="/ekhaya/lens-white.svg" alt="" width="24" height="24" />
          <span className="bold">Cerca</span>
        </button>
      );
  }
};
