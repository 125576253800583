import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import cn from 'classnames';

type Props<T extends Readonly<string[]>> = Readonly<{
  tabs: T;
  activeTab?: T[number];
  children: SameLengthArray<T, React.ReactNode>;
  onTabChange?: (tab: T[number]) => void;
}>;

function Tabs<const T extends Readonly<string[]>>({
  tabs,
  activeTab,
  children: pages,
  onTabChange,
}: Props<T>) {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleOnTabChange = (index: number) => {
    setActiveTabIndex(index);
    onTabChange?.(tabs[index]);
  };

  useEffect(() => {
    if (activeTab) {
      setActiveTabIndex(tabs.indexOf(activeTab));
    }
  }, [activeTab, tabs]);

  return (
    <div className={styles.tabs}>
      <div className={styles.tabSystem} role="tablist">
        {tabs.map((tab, index) => (
          <button
            role="tab"
            key={`tab-${tab}`}
            className={cn('headline-6', styles.tab, {
              [styles.active]: index === activeTabIndex,
            })}
            onClick={() => handleOnTabChange(index)}
          >
            {tab}
          </button>
        ))}
      </div>
      <div className={styles.page}>
        {tabs.map((tab, index) => (
          <div
            role="tabpanel"
            key={`tabpanel-${tab}`}
            hidden={index !== activeTabIndex}
            className={styles.tabContent}
          >
            {pages[index]}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Tabs;

type SameLengthArray<T, MappedType = T> = {
  [key in keyof T]: MappedType;
};
