import React from 'react';
import { Button, Icon } from '@sbt-web/ui';
import SearchModal, { useSearchModal } from '../../../SearchModal';

import styles from './index.module.scss';

export const Mobile = () => {
  const { open, onClose, openTab, activeTab } = useSearchModal('Motori');

  return (
    <>
      <Button
        onClick={() => openTab('Motori')}
        iconLeft={<Icon name="Lens" />}
        classes={[styles.searchButton]}
      >
        Nuova ricerca
      </Button>
      <SearchModal open={open} onClose={onClose} activeTab={activeTab} />
    </>
  );
};
