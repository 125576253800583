import React from 'react';
import { GenericTab } from '../GenericTab';
import { CategoryID } from '@sbt-web/network/types';

export const ImmobiliTab = () => {
  return (
    <GenericTab
      placeholder="Cerca immobili"
      categoryID={CategoryID.Immobili}
      disableRecentSearches
    />
  );
};
