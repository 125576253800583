import { type FC, FormEvent, useReducer } from 'react';
import { CategoryID, SearchParam } from '@sbt-web/network/types';
import { Button, Icon } from '@sbt-web/ui';
import { type GeoLocation, navigateToListing } from '../navigateToListing';
import { GeoSearchInput } from '../../GeoSearchInput';
import { PriceInput } from './PriceInput';
import { BrandInput } from './BrandInput';
import { ModelInput } from './ModelInput';
import { CategoryInput } from './CategoryInput';
import { initialState, motoriSearchFormReducer } from './reducer/searchReducer';
import {
  CATEGORIES_WITH_BRANDS_AND_SIZES,
  SEARCH_PARAMS_BY_CATEGORY,
  VARIANTS,
} from './constants';
import { Variant } from './types';
import cn from 'classnames';

import styles from './index.module.scss';

type Props = {
  variant: Variant;
  ctaWithIcon?: boolean;
  ctaMotorColor?: boolean;
};

export const buildSearchFilters = (
  category: CategoryID,
  brand?: string,
  model?: string,
  price?: string
): Record<string, string> => {
  const filters: Record<string, string> = {};

  if (price) {
    filters[SearchParam.PriceMax] = price;
  }

  const queryParams = SEARCH_PARAMS_BY_CATEGORY[category];

  if (queryParams) {
    if (brand) {
      filters[queryParams.brand] = brand;
    }
    if (model) {
      filters[queryParams.model] = model;
    }
  }

  return filters;
};

const submitSearch = (
  category: CategoryID,
  brand?: string,
  model?: string,
  geo?: GeoLocation,
  price?: string
) => {
  const filters = buildSearchFilters(category, brand, model, price);

  navigateToListing(undefined, category, geo, false, {
    qso: false,
    shp: false,
    filters,
  });
};

export const MotoriSearchForm: FC<Props> = ({
  ctaWithIcon,
  ctaMotorColor,
  variant,
}) => {
  const [state, dispatch] = useReducer(motoriSearchFormReducer, initialState);
  const { category, brand, model, location, price } = state;

  const disabledBrandsAndModels =
    !CATEGORIES_WITH_BRANDS_AND_SIZES.includes(category);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    submitSearch(category, brand, model, location, price);
  };

  return (
    <form className={styles.form} method="POST" onSubmit={handleSubmit}>
      <div
        className={
          variant === VARIANTS.COMPACT
            ? styles['compact-form']
            : styles['linear-form']
        }
      >
        <div>
          <label htmlFor="categoria" className={styles.label}>
            Categoria
          </label>
          <CategoryInput
            value={category}
            onChange={(value) =>
              dispatch({ type: 'SET_CATEGORY', payload: value })
            }
          />
        </div>

        <div>
          <label htmlFor="marca" className={styles.label}>
            Marca
          </label>
          <BrandInput
            categoryID={category}
            value={brand}
            onChange={(value) =>
              dispatch({ type: 'SET_BRAND', payload: value })
            }
            disabled={disabledBrandsAndModels}
          />
        </div>

        <div>
          <label htmlFor="marca" className={styles.label}>
            Modello
          </label>
          <ModelInput
            categoryID={category}
            brand={brand}
            value={model}
            onChange={(value) =>
              dispatch({ type: 'SET_MODEL', payload: value })
            }
            disabled={disabledBrandsAndModels}
          />
        </div>

        <div>
          <label className={styles.label} htmlFor="location-tab-motori">
            Dove?
          </label>
          <GeoSearchInput
            location={location}
            onLocationChange={(value) =>
              dispatch({ type: 'SET_LOCATION', payload: value })
            }
            labelId="location-tab-motori"
          />
        </div>

        <div>
          <label htmlFor="price" className={styles.label}>
            Prezzo €
          </label>
          <PriceInput
            categoryID={category}
            initialValue={price}
            onChange={(value) =>
              dispatch({ type: 'SET_PRICE', payload: value })
            }
          />
        </div>

        <Button
          icon={ctaWithIcon ? <Icon name="Lens" /> : undefined}
          size="large"
          classes={[
            cn(styles.cta, {
              [styles.ctaMotorColor]: ctaMotorColor,
            }),
          ]}
        >
          Cerca
        </Button>
      </div>
    </form>
  );
};
