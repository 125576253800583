import React, { useState } from 'react';
import { CategoryID } from '@sbt-web/network/types';
import { Autocomplete, navigateToListing } from '../../SearchBox/Autocomplete';
import type { SearchValue } from '../../SearchBox/types';

type Props = {
  placeholder: string;
  disableRecentSearches?: boolean;
  categoryID?: CategoryID;
};

export const GenericTab = ({
  placeholder,
  disableRecentSearches,
  categoryID = CategoryID.Tutte,
}: Props) => {
  const [forcedSuggestion, setForcedSuggestion] = useState<SearchValue>();

  const handleOnSelectOption = (option: SearchValue) => {
    // if the option category is not empty means the user selected a suggestion
    if (option.category) {
      return navigateToListing(option);
    }

    // when the user doesn't select a suggestion and there is a forced suggestion, navigate to the forced suggestion
    if (forcedSuggestion) {
      return navigateToListing(forcedSuggestion);
    }

    // when the category is empty and there is no forced suggestion, navigate to the category passed as prop
    navigateToListing({
      ...option,
      category: {
        key: categoryID,
        label: '', // label not needed for navigation
      },
    });
  };

  return (
    <Autocomplete
      onSelectOption={handleOnSelectOption}
      onForcedOptionChange={setForcedSuggestion}
      placeholder={placeholder}
      disableRecentSearches={disableRecentSearches}
      disableMobileDialog
    />
  );
};
