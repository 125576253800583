import React, { useCallback, useState } from 'react';
import { FullModal } from '@sbt-web/ui';
import Tabs from '../Tabs';
import { MotoriTab } from './MotoriTab';
import { MarketTab } from './MarketTab';
import { ImmobiliTab } from './ImmobiliTab';
import { LavoroTab } from './LavoroTab';

const TABS = ['Motori', 'Market', 'Immobili', 'Lavoro'] as const;

export type Tab = (typeof TABS)[number];

type Props = Readonly<{
  open: boolean;
  onClose: () => void;
  activeTab: Tab;
}>;

function SearchModal({ open, onClose, activeTab }: Props) {
  return (
    <FullModal
      inset
      open={open}
      onClose={onClose}
      title="Nuova ricerca"
      smallHeader
    >
      <Tabs tabs={TABS} activeTab={activeTab}>
        <MotoriTab />
        <MarketTab />
        <ImmobiliTab />
        <LavoroTab />
      </Tabs>
    </FullModal>
  );
}

export const useSearchModal = (initialTab: Tab) => {
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<Tab>(initialTab);

  const openTab = useCallback((tab: Tab) => {
    setOpen(true);
    setActiveTab(tab);
  }, []);

  const onClose = useCallback(() => setOpen(false), []);

  return {
    openTab,
    onClose,
    open,
    activeTab,
  };
};

export default SearchModal;
