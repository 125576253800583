import { type FC, useState } from 'react';
import type { Vertical } from '../../categories';
import { Verticals } from '../../Verticals';
import { Verticals as VerticalObj } from '../../categories';
import { VerticalSearchForm } from '../../Verticals/VerticalSearchForm';
import { MotoriSearchForm } from '../../MotoriSearchForm';

import styles from './index.module.scss';

export const Desktop: FC = () => {
  const [activeVertical, setActiveVertical] = useState<Vertical>(
    VerticalObj.Motori
  );

  const isMotoriVertical = activeVertical === VerticalObj.Motori;

  return (
    <>
      <Verticals
        activeVertical={activeVertical}
        onItemClick={setActiveVertical}
      />

      {isMotoriVertical && (
        <div className={styles['search-bar']}>
          <MotoriSearchForm variant="compact" ctaWithIcon ctaMotorColor />
        </div>
      )}

      {!isMotoriVertical && (
        <div className={styles['search-bar']}>
          <VerticalSearchForm variant="compact" vertical={activeVertical} />
        </div>
      )}
    </>
  );
};
