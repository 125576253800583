import { CategoryID } from '@sbt-web/network/types';
import { GeoLocation } from '../../navigateToListing';

type SearchState = {
  category: CategoryID;
  brand?: string;
  model?: string;
  location?: GeoLocation;
  price?: string;
};

type SearchAction =
  | { type: 'SET_CATEGORY'; payload: CategoryID }
  | { type: 'SET_BRAND'; payload: string | undefined }
  | { type: 'SET_MODEL'; payload: string | undefined }
  | { type: 'SET_LOCATION'; payload: GeoLocation | undefined }
  | { type: 'SET_PRICE'; payload: string | undefined };

export const initialState: SearchState = {
  category: CategoryID.Auto,
};

export const motoriSearchFormReducer = (
  state: SearchState,
  action: SearchAction
): SearchState => {
  switch (action.type) {
    case 'SET_CATEGORY':
      return {
        ...state,
        category: action.payload,
        brand: undefined,
        model: undefined,
      };
    case 'SET_BRAND':
      return {
        ...state,
        brand: action.payload,
        model: undefined,
      };
    case 'SET_MODEL':
      return { ...state, model: action.payload };
    case 'SET_LOCATION':
      return { ...state, location: action.payload };
    case 'SET_PRICE':
      return { ...state, price: action.payload };
    default:
      return state;
  }
};
