import React from 'react';
import { CategoryID } from '@sbt-web/network/types';
import { ComboboxInput } from '../ComboboxInput';

type Props = {
  disabled: boolean;
  categoryID: CategoryID;
  value: string | undefined;
  onChange: (value: string | undefined) => void;
};

const CATEGORY_URI_MAP: { [key in CategoryID]?: string } = {
  [CategoryID.Auto]: '/v1/values/cars/brands',
  [CategoryID.Moto]: '/v1/values/motorbikes/brands',
};

export const BrandInput = ({
  value,
  onChange,
  disabled,
  categoryID,
}: Props) => {
  const dataSource = CATEGORY_URI_MAP[categoryID]!;

  return (
    <ComboboxInput
      label="Marca"
      value={value}
      onChange={onChange}
      dataSource={dataSource}
      categoryID={categoryID}
      disabled={disabled}
    />
  );
};
