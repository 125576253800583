'use client';

import { type FC, useContext, useEffect, useState } from 'react';
import { OptimizelySubitoContext } from '@sbt-web/houston-wrapper';
import { useViewport } from '@sbt-web/hooks';
import { SearchBoxA } from './SearchBoxA';
import { SearchBoxB } from './SearchBoxB';
import { SearchBoxC } from './SearchBoxC';

const DESKTOP_TEST_ID = 'sbt-web-hp-motor-search-desktop';
const MOBILE_TEST_ID = 'sbt-web-hp-motor-search-mobile';

export const SearchBox: FC = () => {
  const [abTestVariant, setAbTestVariant] = useState('A');
  const { isMobile } = useViewport();
  const { optimizely } = useContext(OptimizelySubitoContext);

  useEffect(() => {
    let cancelActvation = false;

    optimizely?.onReady().then(() => {
      // the useIsMobile is not reliable at returning the correct value at component mount
      // so we wait for the rerender of the component to get the correct value
      if (cancelActvation) return;

      const variant = optimizely.activate(
        isMobile ? MOBILE_TEST_ID : DESKTOP_TEST_ID
      );

      if (variant) {
        setAbTestVariant(variant);
      }
    });

    return () => {
      cancelActvation = true;
    };
  }, [optimizely, isMobile]);

  if (abTestVariant === 'C' && isMobile) {
    return <SearchBoxC />;
  }

  if (abTestVariant === 'B') {
    return <SearchBoxB isMobile={isMobile} />;
  }

  return <SearchBoxA />;
};
