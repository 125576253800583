import { useEffect, useState } from 'react';
import { getFilterValues } from '@sbt-web/network/client';
import type { FilterValues, KeyValuePair } from '@sbt-web/network/types';

export enum ValueListStatus {
  LOADING,
  LOADED,
  ERROR,
}

export type ValueListData = {
  value?: FilterValues;
  state: ValueListStatus;
};

const INITIAL_VALUE_LIST_STATE: ValueListData = {
  state: ValueListStatus.LOADING,
};

const FAILED_VALUE_LIST_STATE: ValueListData = {
  state: ValueListStatus.ERROR,
};

/**
 * Cache responses with dataSource_category as key
 */
const valueListDataCache = new Map<string, ValueListData>();

export const useValueList = (
  dataSource: string,
  categoryId?: string,
  hasUnsatisfiedDependencies?: boolean
): ValueListData => {
  const [valueListStore, setValueListStore] = useState<ValueListData>(
    INITIAL_VALUE_LIST_STATE
  );

  useEffect(() => {
    if (hasUnsatisfiedDependencies) {
      // If there are unsatisfied dependencies, we don't need to fetch the value list
      // and we can set the state to failed to prevent the component from rendering
      setValueListStore(FAILED_VALUE_LIST_STATE);
      return;
    }

    const cacheKey = `${dataSource}_${categoryId}`;
    setValueListStore(INITIAL_VALUE_LIST_STATE);
    let cancelRenderOptions = false;

    if (valueListDataCache.has(cacheKey)) {
      setValueListStore(valueListDataCache.get(cacheKey)!);
      return;
    }

    const fetchValueList = async () => {
      try {
        const { body: value } = await getFilterValues(
          process.env.NEXT_PUBLIC_HADES_BASE,
          dataSource,
          categoryId
        );

        if (!value.values) throw new Error('No data');

        const valueListData = {
          value,
          state: ValueListStatus.LOADED,
        };

        valueListDataCache.set(cacheKey, valueListData);
        if (cancelRenderOptions) return;

        setValueListStore(valueListData);
      } catch {
        valueListDataCache.set(cacheKey, FAILED_VALUE_LIST_STATE);
        if (cancelRenderOptions) return;
        setValueListStore(FAILED_VALUE_LIST_STATE);
      }
    };

    fetchValueList();

    return () => {
      cancelRenderOptions = true;
    };
  }, [categoryId, dataSource, hasUnsatisfiedDependencies]);

  return valueListStore;
};

export interface ValueListOption {
  name: string;
  value: string;
  weight?: number;
  disabled?: boolean;
  description?: string;
}

type ComboboxOption = {
  label: string;
  value: string;
};

export const selectOptionMapper = (kv: KeyValuePair): ValueListOption => ({
  name: kv.value,
  value: kv.key,
  weight: kv.weight,
  description: kv.description,
});

export function mapValuesToOptions(
  filterValues: FilterValues = {}
): ValueListOption[] {
  const { values = [], topValues } = filterValues;
  const valueList: ValueListOption[] = [];

  if (topValues) {
    valueList.push({
      name: 'Più frequenti',
      value: '',
      disabled: true,
    });
    valueList.push(...topValues.map(selectOptionMapper));
    valueList.push({ name: 'Tutti', value: '', disabled: true });
  }

  valueList.push(...values.map(selectOptionMapper));

  return valueList;
}

export const comboboxOptionMapper = (kv: KeyValuePair): ComboboxOption => ({
  label: kv.value,
  value: kv.key,
});
