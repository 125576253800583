import type { FC } from 'react';
import { Desktop } from './Desktop';
import { Mobile } from './Mobile';

type Props = {
  isMobile: boolean;
};

export const SearchBoxB: FC<Props> = ({ isMobile }) => {
  return isMobile ? <Mobile /> : <Desktop />; //NOSONAR
};
