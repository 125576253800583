import { SelectBox, SelectOption } from '@sbt-web/ui';
import React from 'react';
import { mapValuesToOptions, useValueList } from '../hooks/useValueList';
import { CategoryID } from '@sbt-web/network/types';

type Props = {
  categoryID: CategoryID;
  initialValue?: string;
  onChange: (value?: string) => void;
};

export const PriceInput = ({ initialValue, categoryID, onChange }: Props) => {
  const valuelistData = useValueList(
    `/v1/values/categories/${categoryID}/adtypes/s/prices`
  );

  const valueList = mapValuesToOptions(valuelistData.value);

  const selectedIndeces = buildInitialIndices(initialValue, valueList);

  return (
    <SelectBox
      name="Prezzo"
      valueList={valueList}
      flavor="large"
      placeholder="Qualsiasi"
      selectedIndices={selectedIndeces}
      onSelect={(opt) => onChange(opt?.[0]?.value)}
      nullable
    />
  );
};

export const buildInitialIndices = (
  initialValue: string | undefined,
  valueList: SelectOption[]
): number[] => {
  const initialIndices: number[] = [];
  if (initialValue) {
    const index = valueList.findIndex((v) => v.value === initialValue);
    if (index !== -1) {
      initialIndices.push(index);
    }
  }
  return initialIndices;
};
