import type { FC } from 'react';
import { Modal } from '../../Modal';
import { LeftModal } from './LeftModal';
import { RightModal } from './RightModal';
import type { Vertical } from '../../categories';
import cn from 'classnames';

import styles from './index.module.scss';

type Props = {
  vertical: Vertical;
  show: boolean;
  onClickClose: () => void;
};

export const VerticalModal: FC<Props> = ({ vertical, onClickClose, show }) => (
  <Modal
    useCloseArrow={false}
    modalRight={<RightModal vertical={vertical} />}
    modalLeft={<LeftModal vertical={vertical} />}
    show={show}
    onClose={onClickClose}
    className={cn(styles['modal'], vertical ? styles[vertical.key] : null)}
  />
);
