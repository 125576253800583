import type { FC } from 'react';
import cn from 'classnames';
import type { Vertical } from '../../categories';

import styles from './index.module.scss';

type Props = {
  vertical?: Vertical;
};

export const LeftModal: FC<Props> = ({ vertical }) => (
  <div
    className={cn(styles['sx-inner'], vertical ? styles[vertical.key] : null)}
  >
    <img
      src={`/ekhaya/subito-${vertical?.key}.svg`}
      alt={`Subito ${vertical?.key}`}
      width={168}
    />
  </div>
);
